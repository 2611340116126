import React from 'react'
import { Link } from 'gatsby'
import CtaRow from 'components/cta-row'
import { documentIcon, documentTemplateIcon } from 'images/hulp/tools'
import Helmet from 'react-helmet'
import Layout from '../../layouts'

const BranchesPage = ({location}) => (
  <Layout location={location}>
  <main className="tools branche animated fadeInPage">

    <Helmet>
      <title>Salarisadministratie voor elke branche | Employes</title>
      <meta name="description" content="Employes is de #1 salarisadministratie tool voor veel branches! Salarisadministratie, HR, verlof en meer! Inclusief CAO en pensioen." />
      <meta itemprop="name" content="Salarisadministratie voor elke branche | Employes" />
      <meta itemprop="description" content="Employes is de #1 salarisadministratie tool voor veel branches! Salarisadministratie, HR, verlof en meer! Inclusief CAO en pensioen."/>
      <meta itemprop="image" content="/static/meta-img.png" />
      <meta name="robots" content="noindex,follow" />
    </Helmet>

    <header className="padding-xl">
      <div className="container-md">
        <diV className="grid yg center text-center">
          <div className="col-12">
            <h1>Salarisadministratie voor elke branche</h1>
            <p className="streamer center">Employes heeft klanten uit veel verschillende branches: van horeca tot advocatuur en van marketing tot installatietechniek. Voor elke branche kan er sprake zijn van een specifieke CAO of pensioenregeling. Met Employes zorgen wij ervoor dat de juiste regelgeving wordt toegepast.</p>
          </div>
        </diV>
      </div>
    </header>


    <section className="tools-cards padding-xxl">
      <div className="container-md">
        <div className="grid yg">

          <div className="col-6 margin-l-bottom content-box tools-cards-card">
            <div className="tools-cards-single card">

              <h3 className="margin-xxs-bottom">Horeca</h3>
              <p className="margin-s-bottom">Als ondernemer in de horeca wil je eenvoudig zowel je vaste als oproepkrachten verlonen.</p>
              <Link to="/salarisadministratie-horeca/" className="btn secondary">Lees meer <span className="arrow right"></span></Link>
            </div>
          </div>

            <div className="col-6 margin-l-bottom content-box tools-cards-card">
              <div className="tools-cards-single card">

                <h3 className="margin-xxs-bottom">Detailhandel</h3>
                <p className="margin-s-bottom">Met Employes kun je als eigenaar van een winkel of webshop eenvoudig verlonen.</p>
                <Link to="/salarisadministratie-detailhandel/" className="btn secondary">Lees meer <span className="arrow right"></span></Link>
              </div>
            </div>

            <div className="col-6 margin-l-bottom content-box tools-cards-card">
              <div className="tools-cards-single card">

                <h3 className="margin-xxs-bottom">Kappers</h3>
                <p className="margin-s-bottom">Als kapper wil je je voornamelijk richten op waar je goed in bent, maar de salarisadministratie moet wel goed geregeld zijn.</p>
                <Link to="/salarisadministratie-kappers/" className="btn secondary">Lees meer <span className="arrow right"></span></Link>
              </div>
            </div>

            <div className="col-6 margin-l-bottom content-box tools-cards-card">
              <div className="tools-cards-single card">

                <h3 className="margin-xxs-bottom">ICT</h3>
                <p className="margin-s-bottom">Met Employes kies je voor een moderne en complete oplossing voor je personeel van je IT-bedrijf.</p>
                <Link to="/salarisadministratie-ict/" className="btn secondary">Lees meer <span className="arrow right"></span></Link>
              </div>
            </div>

            <div className="col-6 margin-l-bottom content-box tools-cards-card">
              <div className="tools-cards-single card">

                <h3 className="margin-xxs-bottom">Schoonmaakbedrijf</h3>
                <p className="margin-s-bottom">Een moderne en complete oplossing voor personeel van je schoonmaak- en glazenwassersbedrijf kies je met Employes.</p>
                <Link to="/salarisadministratie-schoonmaak/" className="btn secondary">Lees meer <span className="arrow right"></span></Link>
              </div>
            </div>

            <div className="col-6 margin-l-bottom content-box tools-cards-card">
              <div className="tools-cards-single card">

                <h3 className="margin-xxs-bottom">Metaalbedrijf</h3>
                <p className="margin-s-bottom">Als metaalbedrijf wil je je voornamelijk richten op waar je goed in bent, maar niet druk hoeven maken om alle CAO-regelgeving.</p>
                <Link to="/salarisadministratie-metaal/" className="btn secondary">Lees meer <span className="arrow right"></span></Link>
              </div>
            </div>

        </div>
      </div>
    </section>

    {/*<CtaRow small light text="Kan je niet vinden waar je naar zoekt? Vraag het ons." btnText="Stel een vraag" linkTo="#"/>

    <section className="padding-xxl-top padding-l-bottom">

      <div className="container-md">
        <div className="grid yg margin-l-bottom">
          <div className="col-7">
            <p className="eyebrow">Handig</p>
            <h3 className="margin-xs-bottom">Download onze handige, gratis gidsen</h3>
            <p className="margin-s-bottom">We helpen je graag een handje opweg bij meer text hier die je gewoon kunt verder typen zodat er meer text staat voor deze sectie.</p>
          </div>
        </div>

        <div className="grid yg">

            <div className="col-6 margin-l-bottom content-box">
              <div className="tools-cards-single card">
                <div className="grid">
                  <div className="col-2 no-pad file-icon-image">
                    <img src={documentTemplateIcon}/>
                  </div>

                  <div className="col-10">
                    <p className="eyebrow">Uitleg</p>
                    <h3 className="margin-xxs-bottom">Beginnen met salarisadministratie</h3>
                    <p className="margin-s-bottom">Dolor maecenas nascetur fringilla per cum vivamus phasellus aliquet, dictum nullam vulputate.</p>
                    <Link to="#" className="btn secondary">Download <span className="arrow right"></span></Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 margin-l-bottom content-box">
              <div className="tools-cards-single card">
                <div className="grid">
                  <div className="col-2 no-pad file-icon-image">
                    <img src={documentTemplateIcon}/>
                  </div>

                  <div className="col-10">
                    <p className="eyebrow">Uitleg</p>
                    <h3 className="margin-xxs-bottom">Overstappen van salarisadministratie</h3>
                    <p className="margin-s-bottom">Dolor maecenas nascetur fringilla per cum vivamus phasellus aliquet, dictum nullam vulputate.</p>
                    <Link to="#" className="btn secondary">Download <span className="arrow right"></span></Link>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

    </section>
    */}

  </main>
  </Layout>
)

export default BranchesPage
